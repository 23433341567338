import React, { useState } from 'react';
import { graphql, navigate } from 'gatsby';
import Layout from '../components/Layout';
import SnsShareButtons from '../components/SnsShareButtons';
import Seo from '../components/Seo';
import Image from '../components/Image';
import Title from '../components/Title';
import InputText from '../components/InputText';
import Button from '../components/Button';
import ListItem from '../components/ListItem';
import './style.sass';

export default props => {
  const [inputValue, setInputValue] = useState('');
  const fortunes = props.data.allSelectedCsv.edges;
  const columns = props.data.allColumnCsv.edges;
  const handleChange = e => setInputValue(e.currentTarget.value);

  const handleSearchButtonClick = e => {
    if (inputValue === '') {
      return;
    }

    navigate(`/search`, { state: { keywords: inputValue.split(' ') } });
  };

  return (
    <Layout>
      <Seo
        title="マダムたぬきの夢占い | Topページ"
        description="マダムたぬきの夢占いのTOPページです"
        href={props.location.href}
      />
      <figure className="indexSearch__figure">
        <Image filename="img_normal.png" />
      </figure>
      <p className="index__message">
        ようこそ、マダムたぬきの夢占いへ。
        <br />
        夢に込められているのは重要なメッセージを紐解くお手伝いをさせてちょうだい。
      </p>
      <div className="indexSearch">
        <div className="indexSearch__message">
          あなたの見た夢を教えてちょうだい
        </div>
        <div className="indexSearch__inputText">
          <InputText
            name="keyword"
            handleChange={handleChange}
            placeholder="キーワードを入力してください"
          />
        </div>
        <div className="indexSearch__Button">
          <Button
            label="今すぐ検索する！"
            handleClick={handleSearchButtonClick}
          />
        </div>
      </div>
      <div className="index__pr">
        <a
          href="https://px.a8.net/svt/ejp?a8mat=3BSP80+91O4OI+2H0Q+7V4WH"
          rel="nofollow"
        >
          <img
            width="100%"
            alt="電話占いヴェルニ"
            src="https://www27.a8.net/svt/bgt?aid=201213360547&wid=001&eno=01&mid=s00000011537001321000&mc=1"
          />
        </a>
        <img
          border="0"
          width="1"
          height="1"
          src="https://www14.a8.net/0.gif?a8mat=3BSP80+91O4OI+2H0Q+7V4WH"
          alt="電話占いヴェルニ"
        />
      </div>
      <Title>あなたはこんな夢を見たことがないかしら？</Title>
      <div className="linkList">
        {fortunes.map((item, i) => {
          return (
            <div key={i}>
              <ListItem to={`/selected/${item.node.id}`}>
                <div className="listItem__header">{item.node.title}</div>
                <div className="listItem__body">{item.node.description}</div>
              </ListItem>
              {i % 30 === 1 ? (
                <div className="listItem">
                  <a
                    href="https://px.a8.net/svt/ejp?a8mat=3BSP80+91O4OI+2H0Q+631SY"
                    rel="nofollow"
                  >
                    <div className="listItem__header">[PR]</div>
                    <div className="listItem__body">
                      彼とやり直したい <br />
                      占いで悩み解決☆
                    </div>
                  </a>
                  <img
                    border="0"
                    width="1"
                    height="1"
                    src="https://www18.a8.net/0.gif?a8mat=3BSP80+91O4OI+2H0Q+631SY"
                    alt=""
                  />
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
      {/* <Title>みんながよく見てる夢</Title>
      <div className="linkList">
        {fortunes.map((item, i) => {
          return (
            <ListItem key={i} to={`/fortune/${item.node.id}`}>
              {item.node.title}
            </ListItem>
          );
        })}
      </div> */}
      <Title>夢占いについてのコラム</Title>
      <div className="linkList">
        {columns.map((item, i) => {
          return (
            <ListItem key={i} to={`/column/${item.node.id}`}>
              <div className="listItem__header">{item.node.title}</div>
              <div className="listItem__body">{item.node.description}</div>
            </ListItem>
          );
        })}
      </div>
      <Title type="secondary">Message for you</Title>
      <div className="index__message">
        怖い夢や奇妙な夢…目が覚めていても夢を覚えていた時。
        その夢に込められた重要なメッセージを占いましょう。
        古来中国では夢占いの予言を戦で参考にしたり、古代ギリシャでも古くから夢占いは行われているの。
        日本では「正夢」なんて言葉や「初夢」なんて風習もあるわ。
        夢占いは全ての正解じゃないわ。今日気をつけることをそっと教えてくれるだけ。
        夢の内容でこれから起こる出来事のサインや吉凶を占うことは造作もないことよ。
      </div>
      <div className="SnsShareButtonsWrapper">
        <SnsShareButtons
          location={props.location}
          title={`【夢占い】マダムたぬきがあなたの夢を無料で占います`}
        />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query allSelectedCsv {
    allSelectedCsv {
      edges {
        node {
          id
          title
          description
          body
          score
          parentId
        }
      }
    }
    allColumnCsv {
      edges {
        node {
          id
          title
          description
          body
        }
      }
    }
  }
`;

// export const query = graphql`
//   query allFortuneCsv {
//     allFortuneCsv {
//       edges {
//         node {
//           id
//           title
//           description
//           body
//           selectedIds
//         }
//       }
//     }
//   }
// `;
