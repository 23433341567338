import React from 'react';
import { Link } from 'gatsby';
import './style.sass';

export default ({ to, children, type }) => {
  let classes = 'listItem';
  if (!!type) {
    classes += ` listItem--${type}`;
  }
  return (
    <div className={classes}>
      <Link to={to}>{children}</Link>
    </div>
  );
};
