import React from 'react';
import './style.sass';

export default props => {
  const handleChange = e => {
    if (!props.handleChange) {
      return;
    }
    props.handleChange(e);
  };

  return (
    <div className="inputTextWrapper">
      <input
        name={props.name}
        onChange={handleChange}
        className="inputText"
        type="text"
        defaultValue=""
        placeholder={props.placeholder}
      />
    </div>
  );
};
